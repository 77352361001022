import {ACCOMMODATION_TYPES} from "../../constants";
import {Field, Formik, Form, useFormikContext} from "formik";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {
    FormControl,
    FormErrorMessage,
    Input,
    Stack,
    Heading,
    Button,
    NumberInput,
    NumberInputField,
    Select,
    Textarea,
    FormLabel,
    Checkbox,
    Switch,
    Divider,
    HStack,
    Image,
    CloseButton,
    Box,
    InputGroup,
    Center,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {usePlacesWidget} from "react-google-autocomplete";
import {useDropZoneUpload} from "../../utils/customHooks";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {phoneNumberInputStyles} from "../../styles/phoneNumberInputStyles";
import "../../styles/phoneNumberInput.scss";

function AddressAutocompleteInput({placeholder, ...field}) {
    const {setFieldTouched, setFieldValue} = useFormikContext();

    const extractAddress = (place) => {
        let city, address, street_number, latitude, longitude;

        place["address_components"].forEach((component) => {
            if (component.types.includes("administrative_area_level_2")) {
                city = "Sarajevo";
            }
            if (component.types.includes("locality")) {
                //city = component.long_name;
            }
            if (component.types.includes("route")) {
                address = component.long_name;
            }
            if (component.types.includes("street_number")) {
                street_number = component.long_name;
            }
        });

        latitude = place["geometry"]["location"].lat();
        longitude = place["geometry"]["location"].lng();

        if (street_number) {
            address = `${address} ${street_number}`;
        }

        return {city, address, latitude, longitude};
    };

    const googlePlacesOptions = {
        componentRestrictions: {country: "ba"},
        fields: ["address_components", "geometry"],
        types: ["address"],
        strictBounds: false,
    };

    const {ref} = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            const {city, address, latitude, longitude} = extractAddress(place);
            setFieldValue("city", city);
            setFieldValue("address", address);
            setFieldValue("latitude", latitude);
            setFieldValue("longitude", longitude);
        },
        options: googlePlacesOptions,
    });

    return (
        <Input
            ref={ref}
            value={field.value}
            onChange={(e) => setFieldValue("address", e.target.value)}
            placeholder={placeholder}
            variant="filled"
            onBlur={(e) => {
                setFieldTouched("address", true);
            }}
        />
    );
}


function AccommodationForm({
                               editable = false,
                               accommodationData = {},
                               onSubmit,
                               isSubmitting,
                           }) {
    const {t} = useTranslation(["common", "accommodation"]);
    const {
        selectedFiles,
        cleanBuffer,
        getRootProps,
        getInputProps,
        handleRemoveFile,
    } = useDropZoneUpload(accommodationData.images);
    const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);

    return (
        <Center>
            <Stack width={"500px"} spacing={3}>
                <Heading size={"lg"} mb={8}>
                    {editable ? t("editAccommodation") : t("createAccommodation")}
                </Heading>
                <Formik
                    initialValues={accommodationData}
                    enableReinitialize
                    validationSchema={Yup.object({
                        name: Yup.string().required(t("requiredFieldMessage")),
                        description: Yup.string().required(t("requiredFieldMessage")),
                        rating: Yup.number()
                            .required(t("requiredFieldMessage"))
                            .min(1, t("ratingInvalidMessage"))
                            .max(10, t("ratingInvalidMessage")),
                        address: Yup.string().required(t("requiredFieldMessage")),
                        contact: Yup.object({
                            name: Yup.string().required(t("requiredFieldMessage")),
                            phoneNumber: Yup.string()
                                .required(t("requiredFieldMessage"))
                                .min(8, "Phone number is too short"),
                        }),
                    })}
                    onSubmit={(values, actions) => {
                        const newlyAddedFiles = selectedFiles
                            .filter((file) => !file.id)
                            .map((file) => file.file);

                        onSubmit(
                            {
                                ...values,
                                images_attributes: selectedFiles.filter((file) => file.id),
                            },
                            newlyAddedFiles,
                        );
                        if (!editable) {
                            actions.resetForm();
                            cleanBuffer();
                        }
                    }}
                >
                    {(props) => (
                        <Form>
                            <Stack spacing={3}>
                                <Field name="available">
                                    {({field}) => (
                                        <FormControl>
                                            <Stack direction="row" align="center">
                                                <FormLabel margin="0">
                                                    {t("propertyAvailableLabel")}
                                                </FormLabel>
                                                <Switch
                                                    id="available"
                                                    isChecked={field.value}
                                                    {...field}
                                                />
                                            </Stack>
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider/>

                                <Field name="name">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={form.errors.name && form.touched.name}
                                        >
                                            <Input
                                                {...field}
                                                placeholder={t("nameInputPlaceholder")}
                                                variant="filled"
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="description">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={
                                                form.errors.description && form.touched.description
                                            }
                                        >
                                            <Textarea
                                                {...field}
                                                variant="filled"
                                                placeholder={t("descriptionInputPlaceholder")}
                                                height="200px"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.description}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="address">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={form.errors.address && form.touched.address}
                                        >
                                            <FormLabel>{t("addressFormLabel")}</FormLabel>
                                            <AddressAutocompleteInput
                                                placeholder={t("autocompleteInputPlaceholder")}
                                                {...field}
                                            />
                                            <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="type">
                                    {({field, form}) => {
                                        return (
                                            <FormControl
                                                isInvalid={form.errors.type && form.touched.type}
                                            >
                                                <FormLabel>{t("accommodationTypeFormLabel")}</FormLabel>
                                                <Select
                                                    defaultValue={accommodationData.type}
                                                    variant="filled"
                                                    onChange={(e) => {
                                                        props.setFieldValue("type", e.target.value);
                                                    }}
                                                >
                                                    {Object.keys(ACCOMMODATION_TYPES).map((type) => (
                                                        <option
                                                            key={type}
                                                            label={t(ACCOMMODATION_TYPES[type], {
                                                                ns: "accommodation",
                                                            })}
                                                            value={ACCOMMODATION_TYPES[type]}
                                                        />
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        );
                                    }}
                                </Field>

                                <Field name="cribAvailableOnRequest">
                                    {({field}) => (
                                        <FormControl width="auto">
                                            <Checkbox
                                                isDisabled={
                                                    props.values.type !== ACCOMMODATION_TYPES.HOTEL
                                                }
                                                isChecked={field.value}
                                                {...field}
                                            >
                                                {t("cribOnRequestLabel", {
                                                    ns: "accommodation",
                                                })}
                                            </Checkbox>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="rating">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={form.errors.rating && form.touched.rating}
                                        >
                                            <FormLabel>{t("ratingLabel")}</FormLabel>
                                            <NumberInput variant="filled" {...field}>
                                                <NumberInputField {...field} />
                                            </NumberInput>
                                            <FormErrorMessage>{form.errors.rating}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider/>

                                <FormLabel>{t("contactInfoLabel")}</FormLabel>
                                <Field name="contact.name">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={
                                                form.errors.contact?.name && form.touched.contact?.name
                                            }
                                        >
                                            <Input
                                                {...field}
                                                placeholder={t("nameInputPlaceholder")}
                                                variant="filled"
                                            />
                                            <FormErrorMessage>
                                                {form.errors.contact?.name}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="contact.phoneNumber">
                                    {({field, form}) => (
                                        <FormControl
                                            isInvalid={
                                                form.errors.contact?.phoneNumber &&
                                                form.touched.contact?.phoneNumber
                                            }
                                        >
                                            <InputGroup>
                                                <PhoneInput
                                                    style={{
                                                        ...phoneNumberInputStyles,
                                                        ...(isPhoneInputFocused &&
                                                        !form.errors.contact?.phoneNumber
                                                            ? {
                                                                borderColor: "#3182ce",
                                                                //boxShadow: "0 0 0 1px #3182ce",
                                                            }
                                                            : {}),
                                                        ...(form.errors.contact?.phoneNumber
                                                            ? {
                                                                borderColor: "#E53E3E",
                                                                //boxShadow: "0 0 0 1px #E53E3E",
                                                            }
                                                            : {}),
                                                    }}
                                                    onFocus={() => setIsPhoneInputFocused(true)}
                                                    onBlur={() => setIsPhoneInputFocused(false)}
                                                    placeholder={t("phoneNumberInputPlaceholder")}
                                                    value={field.value}
                                                    onChange={(e) =>
                                                        props.setFieldValue("contact.phoneNumber", e)
                                                    }
                                                />
                                                {/*
                        <InputLeftAddon children="+387" />
                        <Input
                          variant="filled"
                          pr="4.5rem"
                          type="tel"
                          placeholder={t("phoneNumberInputPlaceholder")}
                          {...field}
                        />
                      */}
                                            </InputGroup>
                                            <FormErrorMessage>
                                                {form.errors.contact?.phoneNumber}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider/>

                                <FormLabel>{t("amenitiesLabel")}</FormLabel>
                                <Stack
                                    maxHeight={[null, "400px", "300px", "200px"]}
                                    wrap={"wrap"}
                                    spacing={"6"}
                                >
                                    {Object.keys(props.values).map((amenity, index) => {
                                        if (
                                            typeof props.values[amenity] === "boolean" &&
                                            amenity !== "available" &&
                                            amenity !== "cribAvailableOnRequest"
                                        ) {
                                            return (
                                                <Field key={`${amenity}`} name={`${amenity}`}>
                                                    {({field}) => (
                                                        <FormControl width="auto">
                                                            <Checkbox isChecked={field.value} {...field}>
                                                                {t(`${amenity}`, {ns: "accommodation"})}
                                                            </Checkbox>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            );
                                        } else return null;
                                    })}
                                </Stack>

                                <Divider/>

                                <div>
                                    <FormLabel>{t("imagesLabel")}</FormLabel>
                                    <div
                                        {...getRootProps()}
                                        style={{padding: "20px", border: "2px dashed #ccc"}}
                                    >
                                        <input {...getInputProps()} />
                                        <p>{t("dropZoneMessage")}</p>
                                    </div>
                                    <Box mt="4">
                                        <HStack wrap="wrap" spacing="4">
                                            {selectedFiles.map((image, index) => {
                                                if (image._destroy) {
                                                    return null;
                                                }
                                                return (
                                                    <Box key={index}>
                                                        <Box
                                                            width="50px"
                                                            height="50px"
                                                            position={"relative"}
                                                        >
                                                            <Image src={image.url}/>
                                                            <CloseButton
                                                                size={"sm"}
                                                                position={"absolute"}
                                                                top={"-10px"}
                                                                right={"-10px"}
                                                                onClick={() =>
                                                                    handleRemoveFile(index, image.id)
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </HStack>
                                    </Box>
                                </div>

                                <Button
                                    width="full"
                                    isLoading={isSubmitting}
                                    type="submit"
                                    colorScheme="whatsapp"
                                    mt={4}
                                >
                                    {editable ? t("editAccommodation") : t("createAccommodation")}
                                </Button>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </Center>
    );
}

export default AccommodationForm;
