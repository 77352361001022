import axiosInstance from "./axiosInstance";

const createBooking = (bookingData) =>
  axiosInstance.post(`/bookings`, { booking: bookingData });

const getBookings = (page) =>
  axiosInstance.get(`/bookings`, {
    params: page,
  });

const getBooking = (bookingId) => axiosInstance.get(`/bookings/${bookingId}`);

const getPendingBookings = (page) =>
  axiosInstance.get(`/bookings/pending`, {
    params: page,
  });

const changeBookingStatus = (bookingId, booking) =>
  axiosInstance.patch(`/bookings/${bookingId}/`, { booking });

const submitPhotosToBooking = (bookingId, images, reservationPassport) => {
  const formData = new FormData();
  images.forEach((image, index) => {
    formData.append(`${index}`, image);
  });
  formData.append("reservation_passport", JSON.stringify(reservationPassport));

  return axiosInstance.post(
    `/bookings/${bookingId}/google_drive_images`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        "Key-Inflection": "camel",
      },
    }
  );
};

const authorizeOrder = (bookingId, orderId) => {
  return axiosInstance.post(`/bookings/${bookingId}/paypal/authorize`, {
    orderId: orderId,
  });
};

const captureAuthorizedOrder = (bookingId, orderId) => {
  return axiosInstance
    .post(`/bookings/${bookingId}/paypal/capture_authorized_order`, {
      orderId: orderId,
    })
    .then((response) => {
      //console.log("Authorized order captured successfully:", response.data);
    })

    .catch((error) => {
      //console.error("Error capturing authorized PayPal order:", error);
      throw error; // Optionally rethrow the error to handle it further up the chain
    });
};

const getBookedDatesByBookableId = (bookableId, bookableType) =>
    axiosInstance.get(`/bookings/booking_details/`, {
      params : {
        bookable_id: bookableId,
        bookable_type: bookableType
      }});

export {
  createBooking,
  getBookings,
  getBooking,
  getPendingBookings,
  submitPhotosToBooking,
  changeBookingStatus,
  authorizeOrder,
  captureAuthorizedOrder,
  getBookedDatesByBookableId
};
