import { ACCOMMODATION_TYPES } from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import {
  createAccommodation,
  submitPhotosToAccommodation,
} from "../../services/accommodations";
import AccommodationForm from "../../components/accommodation-form/AccommodationForm";
import { preparePhotosForUpload } from "../../utils/helpers";
import { useEntityCreation, usePhotoUpload } from "../../utils/sharedMutations";

function CreateAccommodation() {
  const { userData } = useAuth();

  const submitPhotosMutation = usePhotoUpload(({ accommodationId, photos }) =>
    submitPhotosToAccommodation(accommodationId, photos)
  );

  const createAccommodationMutation = useEntityCreation((accommodationData) => {
    return createAccommodation({
      ...accommodationData,
      rooms_attributes: accommodationData.rooms,
      contact: {
        ...accommodationData.contact,
        phoneNumber: accommodationData.contact.phoneNumber,
      },
    });
  });

  const onSubmit = async (accommodationData, photos) => {
    
    const accommodation = await createAccommodationMutation.mutateAsync(
      accommodationData
    );

    if (photos.length > 0) {
      await submitPhotosMutation.mutateAsync({
        accommodationId: accommodation.data.id,
        photos: preparePhotosForUpload(photos),
      });
    }
  };

  return (
    <AccommodationForm
      accommodationData={{
        name: "",
        type: ACCOMMODATION_TYPES.HOTEL,
        rating: 9,
        address: "",
        city: "",
        latitude: "",
        longitude: "",
        available: true,
        description: "",
        swimmingPool: true,
        wiFi: true,
        parking: true,
        airConditioning: true,
        heating: true,
        flatScreenTv: true,
        wheelchairAccessible: true,
        cribAvailableOnRequest: false,
        contact: {
          name: userData ? userData.firstName + " " + userData.lastName : "",
          phoneNumber: userData ? userData.telephoneNumber : "",
        },
      }}
      onSubmit={onSubmit}
      isSubmitting={
        createAccommodationMutation.isLoading || submitPhotosMutation.isLoading
      }
      showUnavailableDatesCalendar={false}
    />
  );
}

export default CreateAccommodation;
