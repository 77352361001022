import { useParams } from "react-router-dom";
import {
  getAccommodationBySlug,
  updateAccommodation,
  submitPhotosToAccommodation,
} from "../../services/accommodations";
import AccommodationForm from "../../components/accommodation-form/AccommodationForm";
import { useQuery } from "@tanstack/react-query";
import { preparePhotosForUpload } from "../../utils/helpers";
import { useEntityUpdate, usePhotoUpload } from "../../utils/sharedMutations";

function EditAccommodation() {
  const { slug } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["accommodations", slug],
    queryFn: () => getAccommodationBySlug(slug),
    select: (data) => data.data,
  });

  const submitPhotosMutation = usePhotoUpload(({ accommodationId, photos }) =>
    submitPhotosToAccommodation(accommodationId, photos)
  );

  const editAccommodationMutation = useEntityUpdate((accommodationData) =>
    updateAccommodation({
      ...accommodationData,
      id: data.id,
      rooms_attributes: accommodationData.rooms,
      contact: {
        ...accommodationData.contact,
        phoneNumber: accommodationData.contact.phoneNumber,
      },
    })
  );

  const onSubmit = async (accommodationData, photos) => {
      const accommodation = await editAccommodationMutation.mutateAsync(
          accommodationData
      );
      if (photos.length > 0) {
          await submitPhotosMutation.mutateAsync({
              accommodationId: accommodation.data.id,
              photos: preparePhotosForUpload(photos),
          });
      }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AccommodationForm
      editable
      accommodationData={{
        ...data,
        ...data.amenities,
        contact: {
          ...data.contact,
          phoneNumber: data.contact.phoneNumber,
        },
      }}
      onSubmit={onSubmit}
      isSubmitting={
        editAccommodationMutation.isLoading || submitPhotosMutation.isLoading
      }
    />
  );
}

export default EditAccommodation;
