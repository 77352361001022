import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Center,
  Stack,
  Heading,
  Divider,
  Box,
  VStack,
  HStack,
  Image,
  CloseButton,
  NumberInput,
  NumberInputField,
  Flex, Text,
} from "@chakra-ui/react";
import singleBedIcon from "../../assets/single-bed-icon.svg";
import doubleBedIcon from "../../assets/double-bed-icon.svg";
import extraBedIcon from "../../assets/extra-bed-icon.svg";
import ReactInputMask from "react-input-mask";
import { PlusSquareIcon } from "@chakra-ui/icons";
import {useDropZoneUpload} from "../../utils/customHooks";
import React, {useEffect, useState} from "react";
import {DateObject} from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/mobile.css"
import "react-multi-date-picker/styles/colors/teal.css"
import "./AccommodationUnitForm.scss";

function AccommodationUnitForm({
  accommodationUnitData,
  onSubmit,
  editable = false,
  isSubmitting,
  allowPhotoUpload = true,
                                 showUnavailableDatesCalendar,
    bookedDates,
                                 dateRangesFromDatabase = []
}) {

  function UnavailableDates({unavailableDatesState}) {
    const sortedUnavailableDates = unavailableDatesState.sort((a, b) => {
      return new Date(a[0]) - new Date(b[0]);
    });

    const sortedUnavailableDatesToArrayOfDateObjects= sortedUnavailableDates?.map((value) => {
      return [new DateObject(value[0]).format(), new DateObject(value[1]).format()];
    });

    const unavailableDateElements = sortedUnavailableDatesToArrayOfDateObjects.map((value, index) => {
      return <HStack>
        <Button size={"xs"} variant={"plain"} key={index}>
          {`${(value[0])} - ${(value[1])}`}

        </Button>
        <CloseButton onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          if (confirm("are you sure you want to delete this date")) {
            let updatedBulkCreateState = sortedUnavailableDatesToArrayOfDateObjects.filter(range =>
              new Date(range[0]).getTime() !== new Date(value[0]).getTime() && new Date(range[1]).getTime() !== new Date(value[1]).getTime()
            )
            reserved.map((dateRange) => updatedBulkCreateState.push(dateRange));

            setDateRangeValues(updatedBulkCreateState);
          }}}>
        </CloseButton>
      </HStack>
    });
    return(
        <VStack spacing={2} maxHeight="215px"
                overflowY="auto"
        >
          {unavailableDateElements}
        </VStack>
    )}

  //already boooked date ranges
  const reserved = bookedDates.map((dateRange) => {
    const startDateStr = dateRange.startDate
    const endDateStr = dateRange.endDate
    return [new DateObject(startDateStr).format(), new DateObject(endDateStr).format()];
  });

  //unavailable date from db
  const initialDateRangesFromDb = dateRangesFromDatabase.map((value) => {
    const { startDate, endDate } = value;
    return [new DateObject(startDate).format(), new DateObject(endDate).format()];
  });

  const [bulkCreateDates, setBulkCreateDates] = useState(initialDateRangesFromDb);

  const [initialValue, setInitialValue] = useState([...reserved, ...initialDateRangesFromDb]);

  function isReserved(strDate) {
    return reserved.some(([start, end]) => strDate >= start && strDate <= end);
  }

  function isInInitialDateRangesFromDb(strDate) {
    return initialDateRangesFromDb.some(([start, end]) => strDate >= start && strDate <= end);
  }
  let [dateRangeValues, setDateRangeValues] = useState(initialValue);

  useEffect(() => {

    setInitialValue([...reserved, ...dateRangeValues]);

    //pretvoriti u date objekat svaki u daterange
    //i reserved pretvoriti u date objekat
    const bulkCreate = dateRangeValues.filter(range => {
      const [start, end] = [new DateObject(range[0]).format(), new DateObject(range[1]).format()];
      return !reserved.some(reservedRange => {
        const [reservedStart, reservedEnd] = [
          new DateObject(reservedRange[0]).format(),
          new DateObject(reservedRange[1]).format()
        ]
        return start === reservedStart && end === reservedEnd;
      });
    });

    setBulkCreateDates(bulkCreate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeValues]);

  const { t } = useTranslation("common");
  const {
    selectedFiles,
    cleanBuffer,
    getRootProps,
    getInputProps,
    handleRemoveFile,
  } = useDropZoneUpload(accommodationUnitData.images);

  const SeasonalPrice = ({
    id,
    season,
    pricePerNight,
    onRemove,
    onPriceChange,
    onPeriodChange,
  }) => {
    return (
      <HStack gap={4} align="baseline">
        <Flex align="baseline">
          <FormLabel fontSize="sm">{t("periodLabel")}</FormLabel>
          <Input
            value={season}
            width={"140px"}
            as={ReactInputMask}
            mask="99-99,99-99"
            alwaysShowMask
            onChange={onPeriodChange}
          />
        </Flex>
        <Flex align="flex-end">
          <FormLabel fontSize="sm">{t("priceLabel")}</FormLabel>
          <NumberInput
            width="90px"
            placeholder="120"
            value={pricePerNight}
            variant="filled"
          >
            <NumberInputField onChange={onPriceChange} />
          </NumberInput>
        </Flex>
        <CloseButton size="sm" onClick={onRemove} />
      </HStack>
    );
  };

  return (
    <Center>
      <Stack width={"500px"} spacing={3}>
        <Heading size={"lg"} mb={8}>
          {editable ? t("editAccommodationUnit") : t("createAccommodationUnit")}
        </Heading>
        <Formik
          initialValues={accommodationUnitData}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required(t("requiredFieldMessage")),
            defaultPrice: Yup.number()
              .required(t("requiredFieldMessage"))
              .min(1, t("priceTooLowMessage")),
          })}
          onSubmit={(values, actions) => {
            const newlyAddedFiles = selectedFiles
              .filter((file) => !file.id)
              .map((file) => file.file);

            onSubmit(
                {
                 ...values,
                 images_attributes: selectedFiles.filter((file) => file.id),
                },
                newlyAddedFiles,
                bulkCreateDates
            );

            if (!editable) {
              actions.resetForm();
              cleanBuffer();
            }
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing={3}>
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <Input
                        isDisabled={editable}
                        {...field}
                        placeholder={t("nameInputPlaceholder")}
                        variant="filled"
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                {showUnavailableDatesCalendar && <Box flexShrink={0}>
                  <div style={{margin: "10px "}}>
                    <div className="un-availble">
                      <div className="reserved"/>
                      <p>Already reserved</p>
                    </div>
                    <div className="un-availble">
                      <div className="in-service"/>
                      <p>Unavailable dates</p>
                    </div>
                  </div>
                  <Text fontSize={"sm"}>Set unavailable dates for current accommodation: </Text>
                  <Calendar
                      //className= {width < desktopBreakpoint && "rmdp-mobile"}
                      className="teal"
                      multiple
                      range
                      minDate={new DateObject(Date.now())}
                      value={dateRangeValues}
                      onChange={(ranges) => {
                        console.log("ranges", ranges)
                        ranges.map((value) => console.log("range: ", value?.format?.()));
                        //console.log("ranges[0][0]?.format?.()", ranges[0][0]?.format?.())
                        let lastElementInRanges = ranges[ranges.length - 1];
                        //console.log("last e,em in date ranges", dateRangeValues[dateRangeValues.length - 1]);

                        //const lastEnd = lastElementInRanges[1].format();
                        let intersectsWithReserved = false;
                        if(reserved.length === 0) intersectsWithReserved = false;
                        else {
                          const lastStart = new Date(lastElementInRanges[0].format());
                          const lastEnd = lastElementInRanges.length === 2 ? new Date(lastElementInRanges[1].format()) : lastStart;
                          intersectsWithReserved = reserved.every(reservedRange => {
                            const reservedStart = new Date(reservedRange[0]);
                            const reservedEnd = new Date(reservedRange[1]);
                          //  console.log("reservedStart", reservedStart)
                            // Check for overlap
                            return ((lastStart <= reservedEnd && lastStart >= reservedStart) || (lastEnd >= reservedStart && lastEnd <= reservedEnd) || (lastStart <= reservedStart && lastEnd >= reservedEnd))
                          });
                        }
                          //console.log(intersectsWithReserved)
                          if(intersectsWithReserved) return false;

                        if (lastElementInRanges.length === 2) {
                          //if any element of ranges has the same date value inside dateRanges it means that the range is already taken
                          const isClickedOutsideUnAvailbleDates = dateRangeValues.every(
                            ([start, end]) => ranges.some((range) => range[0]?.format?.() !== start && range[1]?.format?.() !== end)
                        );
                        //console.log("isClickedOutsideUnAvailbleDates", isClickedOutsideUnAvailbleDates)
                        if (!isClickedOutsideUnAvailbleDates) return false;
                          const formattedRanges = ranges.map(range => [
                            new DateObject(range[0]).format(),
                            new DateObject(range[1]).format()
                          ]);
                          setDateRangeValues(formattedRanges)
                        //setDateRangeValues(ranges);
                      }
                      }
                      }
                      mapDays={({ date }) => {
                        let className;
                        const strDate = date.format();
                        if (isReserved(strDate)) className = "reserved";
                        if (isInInitialDateRangesFromDb(strDate)) className = "in-service";
                        if (className) return { className };
                      }}
                      plugins={[
                          <UnavailableDates
                              unavailableDatesState={bulkCreateDates}
                          />
                      ]}
                  />
                </Box>
                }

                <Divider/>

                <VStack mb="4">
                  <HStack>
                    <Image boxSize="25px" src={singleBedIcon}/>
                    <Field name="singleBedCount">
                      {({field, form}) => (
                          <FormControl
                          isInvalid={
                            form.errors.singleBedCount &&
                            form.touched.singleBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>

                  <HStack>
                    <Image boxSize="25px" src={doubleBedIcon} />
                    <Field name="doubleBedCount">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.doubleBedCount &&
                            form.touched.doubleBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>

                  <HStack>
                    <Image boxSize="25px" src={extraBedIcon} />
                    <Field name="extraBedCount">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.extraBedCount &&
                            form.touched.extraBedCount
                          }
                        >
                          <NumberInput
                            min={0}
                            max={10}
                            variant="filled"
                            value={field.value}
                          >
                            <NumberInputField id={`single-bed`} {...field} />
                          </NumberInput>
                        </FormControl>
                      )}
                    </Field>
                  </HStack>
                </VStack>

                <Divider />

                <FormLabel>{t("facilitiesLabel")}</FormLabel>
                <Field name="facilities">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.facilities && form.touched.facilities
                      }
                    >
                      <Input
                        {...field}
                        placeholder={"Balcony, Sea view, ..."}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Field>

                <Divider />

                <FormLabel>{t("seasonalPricesLabel")}</FormLabel>
                <Field name="defaultPrice">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.defaultPrice && form.touched.defaultPrice
                      }
                    >
                      <FormLabel fontSize="sm">
                        {t("defaultPriceLabel")}
                      </FormLabel>
                      <NumberInput variant="filled" {...field}>
                        <NumberInputField {...field} />
                      </NumberInput>
                      <FormErrorMessage>
                        {form.errors.defaultPrice}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="seasonalPricesAttributes">
                  {({ field, form }) => (
                    <FormControl>
                      <VStack gap="2">
                        {field.value?.map((seasonalPrice, index) => {
                          if (seasonalPrice._destroy) {
                            return null;
                          }
                          return (
                            <SeasonalPrice
                              onRemove={() => {
                                if (editable) {
                                  props.setFieldValue(
                                    "seasonalPricesAttributes",
                                    props.values.seasonalPricesAttributes.map(
                                      (p, i) => {
                                        if (i === index) {
                                          return {
                                            ...field.value[index],
                                            _destroy: true,
                                          };
                                        } else {
                                          return p;
                                        }
                                      }
                                    )
                                  );
                                } else
                                  props.setFieldValue(
                                    "seasonalPricesAttributes",
                                    props.values.seasonalPricesAttributes.filter(
                                      (_, i) => i !== index
                                    )
                                  );
                              }}
                              onPriceChange={(e) => {
                                props.setFieldValue(
                                  `seasonalPricesAttributes.${index}.pricePerNight`,
                                  e.target.value
                                );
                              }}
                              onPeriodChange={(e) => {
                                props.setFieldValue(
                                  `seasonalPricesAttributes.${index}.season`,
                                  e.target.value
                                );
                              }}
                              key={index}
                              {...seasonalPrice}
                            />
                          );
                        })}
                      </VStack>
                    </FormControl>
                  )}
                </Field>

                <Button
                  onClick={() => {
                    props.setFieldValue(
                      "seasonalPricesAttributes",
                      props.values.seasonalPricesAttributes.concat({})
                    );
                  }}
                  leftIcon={<PlusSquareIcon />}
                  size="sm"
                  variant="outline"
                >
                  {t("addSeasonalPrice")}
                </Button>

                <Divider />

                {allowPhotoUpload && (
                  <div>
                    <FormLabel>{t("imagesLabel")}</FormLabel>
                    <div
                      {...getRootProps()}
                      style={{ padding: "20px", border: "2px dashed #ccc" }}
                    >
                      <input {...getInputProps()} />
                      <p>{t("dropZoneMessage")}</p>
                    </div>
                    <Box mt="4">
                      <HStack wrap="wrap" spacing="4">
                        {selectedFiles.map((image, index) => {
                          if (image._destroy) {
                            return null;
                          }
                          return (
                            <Box key={index}>
                              <Box
                                width="50px"
                                height="50px"
                                position={"relative"}
                              >
                                <Image src={image.url} />
                                <CloseButton
                                  size={"sm"}
                                  position={"absolute"}
                                  top={"-10px"}
                                  right={"-10px"}
                                  onClick={() =>
                                    handleRemoveFile(index, image.id)
                                  }
                                />
                              </Box>
                            </Box>
                          );
                        })}
                      </HStack>
                    </Box>
                  </div>
                )}

                <Button
                  width="full"
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme="whatsapp"
                  mt={4}
                >
                  {editable
                    ? t("editAccommodationUnit")
                    : t("createAccommodationUnit")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Center>
  );
}

export default AccommodationUnitForm;
