function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

//return the Date in the format expected by the server: YYYY-MM-DD
function getDateInServerExpectedFormat(date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
}

function formatDate(dateTimeString) {
  // Extract the date part from the ISO 8601 datetime string
  const datePart = dateTimeString.split("T")[0];

  return datePart;
}

function formatDateToString(date) {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date)) {
    throw new Error("Invalid Date object");
  }
  // Array of short month names
  const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the year, month, and day from the date
  const year = date.getFullYear();
  //const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const month = shortMonths[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');

  // Format as yyyy-mm-dd
  return `${year}-${month}-${day}`;
}

const dateAfter = (date) => {
  let tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

function preparePhotosForUpload(photos) {
  const formData = new FormData();
  photos.forEach((file) => {
    formData.append("images[]", file);
  });
  return formData;
}

const truncateName = (name, maxLength = 13) => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "...";
  }
  return name;
};

// Function to parse the double value
const parseCurrencyToDouble = (currencyStr) => {
  // Remove non-numeric characters (except for the decimal point)
  const numericString = currencyStr.replace(/[^\d.-]+/g, "");
  return parseFloat(numericString);
};

export {
  capitalize,
  getDateInServerExpectedFormat,
  formatDate,
  formatDateToString,
  preparePhotosForUpload,
  dateAfter,
  truncateName,
  parseCurrencyToDouble,
};
