import axiosInstance from "./axiosInstance";

const getUnavailableDateRangesForBookable = (bookableId, bookableType) =>
    axiosInstance.get(`/unavailable_dates`, {
        params: {
            bookable_id: bookableId,
            bookable_type: bookableType,
        }
        });

const deleteUnavailableDateRangesForBookable = (bookableId, bookableType) =>
    axiosInstance.delete(`/unavailable_dates`, {
        params: {
            bookable_id: bookableId,
            bookable_type: bookableType,
        }
        });

const bulkCreateUnavailableDateRangesForBookable = (bookableId, bookableType, dateRanges) => {
    axiosInstance.post(`/unavailable_dates`, {
        bookable_id: bookableId,
        bookable_type: bookableType,
        date_ranges: dateRanges,
    })

}

export {
    getUnavailableDateRangesForBookable,
    deleteUnavailableDateRangesForBookable,
    bulkCreateUnavailableDateRangesForBookable,
};