import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  updateAccommodationUnit,
  getAccommodationUnitById,
  submitPhotosToAccommodationUnit,
} from "../../services/accommodationUnits";
import { getBookedDatesByBookableId } from "../../services/bookings";
import {
  deleteUnavailableDateRangesForBookable,
  getUnavailableDateRangesForBookable,
  bulkCreateUnavailableDateRangesForBookable
} from "../../services/unavailable_dates"
import AccommodationUnitForm from "../../components/accommodation-unit-form/AccommodationUnitForm";
import { preparePhotosForUpload } from "../../utils/helpers";
import { useEntityUpdate, usePhotoUpload } from "../../utils/sharedMutations";
import { ACCOMMODATION_TYPES } from "../../constants";
import { BOOKING_TYPES } from "../../constants";

function EditAccommodationUnit() {
  const { accommodationType, accommodationUnitId } = useParams();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["accommodation-units", accommodationUnitId],
    queryFn: () => getAccommodationUnitById(accommodationUnitId),
    select: (data) => data.data,
    onSuccess: () => {
      refetchUnavailableDateRanges();
      refetchBookedDates();
    }
  });

  const { data: dateRanges, isLoading: isLoadingDateRanges, refetch: refetchUnavailableDateRanges } = useQuery({
    queryKey: ["dateRanges", data],
    queryFn: () => getUnavailableDateRangesForBookable(data.id, BOOKING_TYPES.STAY),
    enabled: !!data,
    select: (data) => data.data.results,
    onSuccess: (data) => {
      //console.log(data)
    },
  })

  const { data: bookedDates, isLoading: isLoadingBookedDates, refetch: refetchBookedDates } = useQuery({
    queryKey: ["bookedDates", data],
    queryFn: () => getBookedDatesByBookableId(data.id, BOOKING_TYPES.STAY),
    enabled: !!data,
    select: (data) => data.data.results,
    onSuccess: (data) => {
    },
  })

  const submitPhotosMutation = usePhotoUpload(
    ({ accommodationUnitId, photos }) =>
      submitPhotosToAccommodationUnit(accommodationUnitId, photos)
  );

  const editAccommodationUnitMutation = useEntityUpdate(
    (accommodationUnitData) =>
      updateAccommodationUnit({
        ...accommodationUnitData,
        id: data.id,
      })
  );

  const onSubmit = async (
    //accommodationId = null,
    accommodationUnitData,
    photos,
    unavailableDateRanges
  ) => {
    const accommodationUnit = await editAccommodationUnitMutation.mutateAsync(
      accommodationUnitData
    );

    if (photos?.length > 0) {
      await submitPhotosMutation.mutateAsync({
        accommodationUnitId: accommodationUnit.data.id,
        photos: preparePhotosForUpload(photos),
      });
    }
    await deleteUnavailableDateRangesForBookable(accommodationUnitData.id, BOOKING_TYPES.STAY)
        .then(() => {
          bulkCreateUnavailableDateRangesForBookable(accommodationUnitData.id, BOOKING_TYPES.STAY, unavailableDateRanges);
        })

    refetch();
  };

  if (isLoading || isLoadingDateRanges || isLoadingBookedDates) {
    return <div>Loading...</div>;
  }

  return (
    <AccommodationUnitForm
      editable
      accommodationUnitData={{
        ...data,
        seasonalPricesAttributes: data.seasonalPrices,
      }}
      onSubmit={onSubmit}
      isSubmitting={
        editAccommodationUnitMutation.isLoading ||
        submitPhotosMutation.isLoading
      }
      allowPhotoUpload={accommodationType === ACCOMMODATION_TYPES.HOTEL}
      showUnavailableDatesCalendar={true}
      bookedDates={bookedDates}
      dateRangesFromDatabase={dateRanges}
    />
  );
}

export default EditAccommodationUnit;
